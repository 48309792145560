import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import {store} from "./app/store/store";

ReactDOM.render(
  <BrowserRouter basename="/diet">
  <Provider store={store}>
    <App />
    </Provider>
  </BrowserRouter>
  , document.getElementById('root'));

serviceWorker.unregister();