import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "./Component/Loader/Spinner";
import axios from 'axios';

const Login = lazy(() => import("./user-page/Login.js"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const SliderForm = lazy(() => import("./Component/Slider/SliderForm.js"));
const SliderData = lazy(() => import("./Component/Slider/SliderData.js"));
const HonoroablePersonData = lazy(() =>
  import("./Component/HonorablePerson/HonorablePersonData.js")
);
const AboutForm = lazy(() => import("./Component/About/AboutForm.js"));
const AboutData = lazy(() => import("./Component/About/AboutData.js"));
const TestimonialForm = lazy(() =>
  import("./Component/Message_Testimonial/TestimonialForm.js")
);
const TestimonialData = lazy(() =>
  import("./Component/Message_Testimonial/TestimonialData.js")
);
const FacultyForm = lazy(() => import("./Component/Faculty/FacultyForm.js"));
const FacultyData = lazy(() => import("./Component/Faculty/FacultyData.js"));
const CourseForm = lazy(() => import("./Component/Course/CourseForm.js"));
const CourseData = lazy(() => import("./Component/Course/CourseData.js"));
const EventsForm = lazy(() => import("./Component/Events/EventsForm.js"));
const EventsData = lazy(() => import("./Component/Events/EventsData.js"));
const TrainingForm = lazy(() => import("./Component/Training/TrainingForm.js"));
const TrainingData = lazy(() => import("./Component/Training/TrainingData.js"));
const GalleryForm = lazy(() => import("./Component/Gallery/GalleryForm.js"));
const GalleryData = lazy(() => import("./Component/Gallery/GalleryData.js"));
const NoticeForm = lazy(() => import("./Component/Notice/NoticeForm.js"));
const NoticeData = lazy(() => import("./Component/Notice/NoticeData.js"));

class AppRoutes extends Component {

  // state = {
  //   typeA: "",
  // }

  // baseURL = process.env.REACT_APP_API_URI;
  // userId = localStorage.getItem("userID");
  // console.log(localStorage.getItem("userID"));
  // fetchData = async () => {
  //   await axios.get(`${this.baseURL}/diet/user/find/${this.userId}`).then((result) => {
  //     // console.log(result.data.type);
  //     this.setState({ typeA: result.data.type });
  //   }).catch((err) => {
  //     console.log(err);
  //   });
  // }

  // componentDidMount() {
  //   this.fetchData();
  // }

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        {localStorage.getItem("userID") ? (
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route path="/sliderForm" component={SliderForm} />
            <Route path="/sliderData" component={SliderData} />
            <Route
              path="/honoroablePersonData"
              component={HonoroablePersonData}
            />
            <Route path="/aboutForm" component={AboutForm} />
            <Route path="/aboutData" component={AboutData} />
            <Route path="/testimonialForm" component={TestimonialForm} />
            <Route path="/testimonialData" component={TestimonialData} />
            <Route path="/facultyForm" component={FacultyForm} />
            <Route path="/facultyData" component={FacultyData} />
            <Route path="/courseForm" component={CourseForm} />
            <Route path="/courseData" component={CourseData} />
            <Route path="/eventsForm" component={EventsForm} />
            <Route path="/eventsData" component={EventsData} />
            <Route path="/trainingForm" component={TrainingForm} />
            <Route path="/trainingData" component={TrainingData} />
            <Route path="/galleryForm" component={GalleryForm} />
            <Route path="/galleryData" component={GalleryData} />
            <Route path="/noticeForm" component={NoticeForm} />
            <Route path="/noticeData" component={NoticeData} />
            <Redirect to="/dashboard" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/login" component={Login} />
            <Redirect to="/login" />
          </Switch>
        )}
      </Suspense>
    );
  }
}

export default AppRoutes;
