import { configureStore, createSlice } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  curUser: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.curUser = action.payload;
      // console.log(action.payload);
    },
    logout: (state) => {
      localStorage.removeItem("userID");
      state.isLoggedIn = false;
    },
  },
});

export const authAction = authSlice.actions;

const reducer = combineReducers({
  user: authSlice.reducer,
});

export const store = configureStore({
  reducer,
});

// console.log(store);
