import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/slider', state: 'sliderMenuOpen' },
      { path: '/honorablePerson', state: 'honorablePersonMenuOpen' },
      { path: '/about', state: 'aboutMenuOpen' },
      { path: '/testimonial', state: 'testimonialMenuOpen' },
      { path: '/faculty', state: 'facultyMenuOpen' },
      { path: '/course', state: 'courseMenuOpen' },
      { path: '/events', state: 'eventsMenuOpen' },
      { path: '/training', state: 'trainingMenuOpen' },
      { path: '/gallery', state: 'galleryMenuOpen' },
      { path: '/notice', state: 'noticeMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={evt => evt.preventDefault()}>
              <div className="nav-profile-image">
                <img src={require("../../assets/images/faces/face1.jpg")} alt="profile" />
                <span className="login-status online"></span> {/* change to offline or busy as needed */}
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2">Diet Admin</span>
                <span className="text-secondary text-small">Principle</span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>

          <li className={this.isPathActive('/slider') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.sliderMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('sliderMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Slider</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-image"></i>
            </div>
            <Collapse in={this.state.sliderMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/sliderForm') ? 'nav-link active' : 'nav-link'} to="/sliderForm">Add Slider</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/sliderData') ? 'nav-link active' : 'nav-link'} to="/sliderData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/honorablePerson') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.honorablePersonMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('honorablePersonMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Honorable Person</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-image"></i>
            </div>
            <Collapse in={this.state.honorablePersonMenuOpen}>
              <ul className="nav flex-column sub-menu">
                {/* <li className="nav-item"> <Link className={this.isPathActive('/sliderForm') ? 'nav-link active' : 'nav-link'} to="/sliderForm">Add Slider</Link></li> */}
                <li className="nav-item"> <Link className={this.isPathActive('/honoroablePersonData') ? 'nav-link active' : 'nav-link'} to="/honoroablePersonData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/about') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.aboutMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('aboutMenuOpen')} data-toggle="collapse">
              <span className="menu-title">About</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={this.state.aboutMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/aboutForm') ? 'nav-link active' : 'nav-link'} to="/aboutForm">Add About</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/aboutData') ? 'nav-link active' : 'nav-link'} to="/aboutData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/testimonial') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.testimonialMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('testimonialMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Testimonial</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-message-text menu-icon"></i>
            </div>
            <Collapse in={this.state.testimonialMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/testimonialForm') ? 'nav-link active' : 'nav-link'} to="/testimonialForm">Add Testimonial</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/testimonialData') ? 'nav-link active' : 'nav-link'} to="/testimonialData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/faculty') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.facultyMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('facultyMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Faculty</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-account-box"></i>
            </div>
            <Collapse in={this.state.facultyMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/facultyForm') ? 'nav-link active' : 'nav-link'} to="/facultyForm">Add Faculty</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/facultyData') ? 'nav-link active' : 'nav-link'} to="/facultyData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/course') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.courseMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('courseMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Course</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-book-open-variant"></i>
            </div>
            <Collapse in={this.state.courseMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/courseForm') ? 'nav-link active' : 'nav-link'} to="/courseForm">Add Course</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/courseData') ? 'nav-link active' : 'nav-link'} to="/courseData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/events') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.eventsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('eventsMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Events</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-timetable"></i>
            </div>
            <Collapse in={this.state.eventsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/eventsForm') ? 'nav-link active' : 'nav-link'} to="/eventsForm">Add Events</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/eventsData') ? 'nav-link active' : 'nav-link'} to="/eventsData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/training') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.trainingMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('trainingMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Training</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-presentation"></i>
            </div>
            <Collapse in={this.state.trainingMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/trainingForm') ? 'nav-link active' : 'nav-link'} to="/trainingForm">Add Training</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/trainingData') ? 'nav-link active' : 'nav-link'} to="/trainingData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/gallery') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.galleryMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('galleryMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Gallery</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-folder-multiple-image"></i>
            </div>
            <Collapse in={this.state.galleryMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/galleryForm') ? 'nav-link active' : 'nav-link'} to="/galleryForm">Add Image or Video</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/galleryData') ? 'nav-link active' : 'nav-link'} to="/galleryData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={this.isPathActive('/notice') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.noticeMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('noticeMenuOpen')} data-toggle="collapse">
              <span className="menu-title">Notice</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-message-draw"></i>
            </div>
            <Collapse in={this.state.noticeMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/noticeForm') ? 'nav-link active' : 'nav-link'} to="/noticeForm">Add new Notice</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/noticeData') ? 'nav-link active' : 'nav-link'} to="/noticeData">Data</Link></li>
              </ul>
            </Collapse>
          </li>

        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);